/* TODO: 
  Create pages for each service and link the LearnMore and SeeProject links to each
*/

import React from "react";
import styled from "styled-components";
//----------COMPONENTS----------
import { PaddingBox, VerticalDivider, Link } from "./globals";
//----------MATERIAL----------
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
//----------DATA----------
import ServicesArray from "../data/services";

//----------GENERAL COMPONENTS----------

const TapMore = () => (
  <Hidden mdUp>
    <PaddingBox>
      <Typography variant="h3">tap to learn more</Typography>
      <VerticalDivider m="1em 0" />
    </PaddingBox>
  </Hidden>
);

const ClickMore = () => (
  <Hidden mdDown>
    <PaddingBox>
      <VerticalDivider m="1em 0" />
      <Typography variant="h3">Click for more Info</Typography>
    </PaddingBox>
  </Hidden>
);

const CardContainer = styled(Grid)`
  width: 100%;
  height: 85.85vw;
  max-height: 358px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
`;

const CardBodyPadding = styled(PaddingBox)`
  padding-top: 2em;
`;

//----------FOR MOBILE----------

const ServiceCardMobile = ({ service: { header, body, image } }) => {
  const [display, setDisplay] = React.useState(false);

  function click() {
    setDisplay(!display);
  }

  const Header = (
    <Box
      bgcolor="rgba(255, 255, 255, 0.75)"
      height="64px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4">{header}</Typography>
    </Box>
  );

  const Body = (
    <CardBodyPadding
      bgcolor="rgba(255, 255, 255, 0.75)"
      height="calc(100% - 64px)"
    >
      <Typography variant="body1">{body}</Typography>
    </CardBodyPadding>
  );

  return (
    <CardContainer item image={image} onClick={click}>
      {Header}
      {display && Body}
    </CardContainer>
  );
};

//----------FOR DESKTOP----------

const CardBodyDesktop = styled(CardBodyPadding)`
  cursor: pointer;
  transition: transform 150ms;
  opacity: ${(props) => (props.$activeIndex === props.$displayOnIndex ? 1 : 0)};
`;

const DesktopCardContainer = styled(CardContainer)`
  width: 50%;
  height: 50%;

  ${CardBodyDesktop} {
    transform: ${(props) =>
      props.$activeIndex === props.$displayOnIndex
        ? "translateX(0)"
        : "translateX(-2.5em)"};
  }

  &:nth-of-type(even) {
    padding-right: 2.2em;
  }

  &:nth-of-type(odd) {
    text-align: right;
    padding-left: 2.2em;

    ${CardBodyDesktop} {
      transform: ${(props) =>
        props.$activeIndex === props.$displayOnIndex
          ? "translateX(0)"
          : "translateX(2.5em)"};
    }
  }
`;

function ServiceCardDesktop({ service, onClick, activeIndex }) {
  const BodyText = (
    <CardBodyDesktop
      height="100%"
      $activeIndex={activeIndex}
      $displayOnIndex={service.displayOnIndex}
    >
      <Typography variant="h4" paragraph>
        {service.header}
      </Typography>
      <Typography variant="body1">{service.body}</Typography>
    </CardBodyDesktop>
  );

  return (
    <DesktopCardContainer
      item
      image={activeIndex === service.displayOnIndex ? null : service.image}
      onClick={onClick}
      $activeIndex={activeIndex}
      $displayOnIndex={service.displayOnIndex}
    >
      {BodyText}
    </DesktopCardContainer>
  );
}

const LearnMoreLine = styled(Box)`
  display: ${(props) => props.$display};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60vw;
  border: 1px solid #000;
`;

const DividerLink = styled(Link)`
  position: inherit;
  right: 0;
`;
const LearnMoreLink = styled(DividerLink)`
  bottom: 1em;
`;
const SeeProjectsLink = styled(DividerLink)`
  top: 1em;
`;

const LearnMoreDivider = (props) => {
  const Before = (
    <LearnMoreLink to="/">
      <Typography variant="h3" component="p">
        Learn More
      </Typography>
    </LearnMoreLink>
  );

  const After = (
    <SeeProjectsLink to="/">
      <Box
        component="p"
        fontFamily="Montserrat"
        fontSize="1.5rem"
        fontWeight="400"
      >
        See Projects
      </Box>
    </SeeProjectsLink>
  );
  return (
    <LearnMoreLine {...props}>
      {Before}
      {After}
    </LearnMoreLine>
  );
};

export default () => {
  const [activeIndex, setActiveIndex] = React.useState(false);

  function click(index) {
    if (activeIndex === index) {
      setActiveIndex(false);
    } else {
      setActiveIndex(index);
    }
  }

  const MobileCards = (
    <Hidden mdUp>
      {ServicesArray.map((service) => (
        <ServiceCardMobile service={service} key={service.header} />
      ))}
    </Hidden>
  );

  const DesktopCards = (
    <Hidden smDown>
      {ServicesArray.map((service, index) => (
        <ServiceCardDesktop
          key={service.header}
          activeIndex={activeIndex}
          service={service}
          onClick={() => click(index)}
        />
      ))}
    </Hidden>
  );

  return (
    <Box height={{ md: "66.4vh" }} position="relative">
      <TapMore />
      <LearnMoreDivider
        $display={activeIndex === 0 || activeIndex ? "block" : "none"}
      />
      <Grid container component={Box} height="inherit">
        {MobileCards}
        {DesktopCards}
        <ClickMore />
      </Grid>
    </Box>
  );
};
