import Kitchen2x from "../assets/kitchen-2x.png";
import NewConstructionImg from "../assets/fwdphotosforwebsite/worker-hole1.jpg"
import AdditionsImg from "../assets/fwdphotosforwebsite/small-frame.jpg"
import ManagementImg from "../assets/fwdphotosforwebsite/fin-home3.jpg"

export default [
  {
    displayOnIndex: 1,
    image: `${NewConstructionImg}`,
    header: "remodel",
    body: `Smith Built doesn't work only on new construction projects. We can 
    also provide remodeling services for your existing home. Is your bathroom 
    literally out of the 70's? Does your kitchen turn meal prep into a 
    harrowing experience? Maybe your bedroom just needs a refresh. No matter 
    the project, Smith Built can make your home new again.`,
  },
  {
    displayOnIndex: 0,
    image: `${AdditionsImg}`,
    header: "new construction",
    body: `Smith Built isn't like other construction companies. We don't work 
    exclusively on a limited set of blueprints. Whatever your blueprint calls 
    for, from a first home to a custom dream home away from the everyday bustle, 
    Smith Built would be proud to handle your new construction needs`,
  },
  {
    displayOnIndex: 3,
    image: `${ManagementImg}`,
    header: "management",
    body: `Every project needs competent management. Even the best plans can fall 
    apart without someone to handle the little things that keep a project on time 
    and of high quality. Smith Built's owner, Jake Smith, offers more than 37 
    years of experience in all aspects of home construction. Bring your project 
    to Smith Built, and we'll do the rest.`,
  },
  {
    displayOnIndex: 2,
    image: `${Kitchen2x}`,
    header: "additions",
    body: `Feeling cramped? Has your family outgrown your home? Perhaps you just 
    want some more space. That's why Smith Built offers addition services. 
    Perhaps you want to extend your living room for better entertaining. Maybe 
    your kitchen is getting too cramped as your family grows. Or you want the 
    master bedroom/bathroom extended. Of course, Smith Built also offers 
    construction of new rooms for your home. If you need more space in your 
    existing home, Smith Built are the ones to call`,
  },
];
