import React from "react";
import styled from "styled-components";
//-----COMPONENTS-----
import { MainBreakpointGrid } from "../components/globals";
import Layout from "../components/layout";
import GreyPanels from "../components/greyPanels";
import PageInfo from "../components/pageInfo";
import ServicesCards from "../components/servicesCards";
import KittyCornerTiles from "../components/kittyCornerTiles";
//-----MATERIAL-----
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
//-----ASSETS-----
import Frame from "../assets/frame.png";
import TileImage1 from "../assets/image-1.png";
import TileImage2 from "../assets/image-2.png";
import Image3 from "../assets/image-3.png";

import { AboutUs } from "../data/pageDetails";

const ImgContainer = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  width: 100%;
  height: 80.85%;
  object-fit: cover;
  object-position: 63% 170px;
  ${({ theme }) => `
    ${theme.breakpoints.up("lg")} {
      width: 100%;height: 105%;
      padding-top: 265px;
      object-fit: inherit;
      object-position: 0 0;
    }
  `}
`;

const SloganContainer = styled(Grid)`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 6.1em;
  padding-bottom: 0.3em;
  font-family: "Montserrat";
  font-size: 1.5rem;
  text-transform: lowercase;
  border-bottom: 2px solid #000;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      top: 9.05em;
      padding-bottom: 0.5em;
    }
  `}
`;

const Slogan = styled(Grid)`
  min-width: 170px;
  width: min-content;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      width: auto;
    }
  `};
`;

export default () => {
  return (
    <Layout>
      <GreyPanels />

      <SloganContainer container>
        <Slogan item>QUALITY WORK • TIMELY FASHION</Slogan>
      </SloganContainer>

      <ImgContainer>
        <Img src={Frame} alt="The frame of a house" />
      </ImgContainer>

      <Grid
        container
        item
        direction="row-reverse"
        component={Box}
        position="relative"
        zIndex="1"
      >
        <MainBreakpointGrid>
          <PageInfo content={AboutUs} bgImage={Image3} />
        </MainBreakpointGrid>

        <MainBreakpointGrid>
          <KittyCornerTiles tileOne={TileImage1} tileTwo={TileImage2} />
          <Box mb="3em" />
        </MainBreakpointGrid>

        <MainBreakpointGrid />

        <MainBreakpointGrid>
          <ServicesCards />
        </MainBreakpointGrid>
      </Grid>
    </Layout>
  );
};
